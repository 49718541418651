<template>

  <div class="jsx-2204232843 ftr_wrap">
    <div class="jsx-2204232843 bottom_ctnt ">
      <div style="height:43px;margin:0" class="jsx-2204232843 bottom_ctnt_w"></div>
      <div style="margin-top:120px;margin-bottom:120px" class="jsx-2204232843 bottom_ctnt_try">
        <button class="jsx-2204232843" @click="$router.push('/')">现在开启</button>
      </div>
    </div>
    <footer class="jsx-2204232843 ftr">
      <div class="jsx-2204232843 ftr_content">
        <div class="jsx-2204232843 fc_left">
          <MetaGoLogo :use-simple="true"></MetaGoLogo>
        </div>
        <div class="jsx-2204232843 fc_right">
          <ul class="jsx-2204232843 fc_solution">
            <li class="jsx-2204232843 title">智能合同</li>
            <li class="jsx-2204232843 col666"><a href="/" class="jsx-2204232843">产品介绍</a></li>
          </ul>
          <ul class="jsx-2204232843 fc_service">
            <li class="jsx-2204232843 title">秘塔团队</li>
            <li class="jsx-2204232843 col666"><a href="https://metasota.ai" target="_blank" class="jsx-2204232843">进入官网</a></li>
          </ul>
          <ul class="jsx-2204232843 fc_contact_us">
            <li class="jsx-2204232843 title">联系我们</li>
            <li class="jsx-2204232843 cold8"><a href="/joinUs" class="jsx-2204232843">关于我们</a></li>
            <li class="jsx-2204232843 cold8">商务合作：<a href="mailto:business@fir.ai" class="jsx-2204232843">business@metasota.ai</a>
            </li>
<!--            <li class="jsx-2204232843 cold8">投资意向：<a href="mailto:investors@fir.ai" class="jsx-2204232843">investors@metasota.ai</a>-->
<!--            </li>-->
          </ul>
        </div>
      </div>
      <div class="jsx-2204232843 ftr_bottom">
        <div class="jsx-2204232843">
<!--          <div class="jsx-2204232843 friendly_link"><span class="jsx-2204232843">友情链接：</span><span-->
<!--              class="jsx-2204232843"><a href="https://www.office26.com/" target="_blank" class="jsx-2204232843">Office教程网</a></span><span-->
<!--              class="jsx-2204232843"><a href="http://www.cninfo.com.cn/new/index" target="_blank"-->
<!--                                        class="jsx-2204232843">巨潮资讯</a></span><span class="jsx-2204232843"><a-->
<!--              href="https://www.ucloud.cn/" target="_blank" class="jsx-2204232843">Ucloud</a></span><span-->
<!--              class="jsx-2204232843"><a href="http://www.wanfangdata.com.cn/" target="_blank" class="jsx-2204232843">万方数据</a></span><span-->
<!--              class="jsx-2204232843"><a href="http://www.cqvip.com/" target="_blank"-->
<!--                                        class="jsx-2204232843">维普数据</a></span><span class="jsx-2204232843"><a-->
<!--              href="https://www.cnki.net/" target="_blank" class="jsx-2204232843">中国知网</a></span><span-->
<!--              class="jsx-2204232843"><a href="https://www.zaojiance.com/" target="_blank"-->
<!--                                        class="jsx-2204232843">论文查重</a></span></div>-->
          <div class="jsx-2204232843 company_info">Copyright ©2022 上海秘塔网络科技有限公司</div>
          <div class="jsx-2204232843 company_info">办公地址：上海市浦东新区南汇新城镇环湖西二路888号C楼</div>
          <div class="jsx-2204232843 company_icp">
            <span class="jsx-2204232843 info_icp info_icp_link">沪ICP备18046118号-6</span>
<!--            <img src="../../assets/images/beian@2x.png" class="jsx-2204232843 beian">-->
<!--            <span class="jsx-2204232843 info_icp">沪公网安备 123 号</span>-->
<!--            <span class="jsx-2204232843 info_linkto"><a class="jsx-2204232843">法律声明</a></span>-->
<!--            <span class="jsx-2204232843 info_linkto">|</span>-->
            <span class="jsx-2204232843 info_linkto"><a class="jsx-2204232843" @click="
             (e)=>{
               e.preventDefault();
               const page = $router.push({name:'privacyPolicy'});
             }
             ">隐私政策</a></span>
            <span class="jsx-2204232843 info_linkto">|</span>
            <span class="jsx-2204232843 info_linkto"><a class="jsx-2204232843" @click="
             (e)=>{
               e.preventDefault();
               const page = $router.push({name:'servicePolicy'});
             }
             ">服务条款</a></span>
          </div>
        </div>
        <div class="jsx-2204232843 wechat_logo"><img src="../../assets/images/contact-qrcode.jpg" class="jsx-2204232843"></div>
      </div>
    </footer>
  </div>
</template>

<script>

import MetaGoLogo from "../universalUI/MetaGoLogo";
export default {
  name: "Homeftr",
  components: {MetaGoLogo},
  props: [],
  data: function () {
    return {
      window
    }
  },
  methods: {
  },
}
</script>

<style scoped>
.ftr_wrap {
  text-align: start;
}
.ftr_wrap.jsx-2204232843 {
  background: #fff;
  font-family: Avenir, Helvetica, Arial, sans-serif;
}

.bottom_ctnt.jsx-2204232843 {
  background: #ffffff;
  position: relative;
  text-align: center;
  min-width: 1200px;
}

.bottom_ctnt.lightingColor.jsx-2204232843 {
  background: #f2faff;
}

.bottom_ctnt_w.jsx-2204232843 {
  width: 580px;
  height: 45px;
  font-size: 24px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-weight: 300;
  color: #666;
  line-height: 24px;
  margin: 25px auto 5px;
}

.bottom_ctnt_w_online.jsx-2204232843 {
  color: #333;
  font-weight: 400;
}

.bottom_ctnt_try.jsx-2204232843 {
  padding-bottom: 70px;
}

bottom_ctnt_try.jsx-2204232843:focus, .bottom_ctnt_try.jsx-2204232843 button.jsx-2204232843:focus {
  outline: 0;
}

.bottom_ctnt_try.jsx-2204232843 button.jsx-2204232843 {
  cursor: pointer;
  width: 120px;
  height: 54px;
  background: #5280ff;
  border-radius: 6px;
  border: none;
  font-size: 20px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-weight: 500;
  color: #ffffff;
}

.ftr {
  height: 420px;
  background-color: #fff;
  color: #fff;
  width: 1200px;
  margin: 0 auto;
  cursor: default;
  min-width: 1200px;
}
.ftr li:hover {
  color: #fff;
}
.ftr li:hover a {
  color: #fff;
}
.ftr li a {
  font-size: 16px !important;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-weight: 300 !important;
  color: #d8d8d8;
}
.ftr .ftr_content {
  height: 210px;
  border-top: 1px solid #d8d8d8;
  min-width: 1200px;
  justify-content: space-between;
  align-items: flex-start;
}
.ftr .ftr_content .fc_left {
  float: left;
  padding: 62px 0 0 0;
}
.ftr .ftr_content .fc_left .logo {
  height: 33.43px;
  width: 149.7px;
}
.ftr .ftr_content .fc_left .fc_left_info {
  width: 270px;
  height: 20px;
  font-size: 14px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-weight: 400;
  color: #ffffff;
  line-height: 20px;
  letter-spacing: 1px;
  margin: 19px 0 0 0;
}
.ftr .ftr_content .fc_right {
  float: right;
  padding: 62px 0 0 0;
}
.ftr .ftr_content .fc_right .fc_service {
  float: left;
  list-style-type: none;
  margin: 0;
  padding: 0 100px 0 0;
}
.ftr .ftr_content .fc_right .fc_service li {
  list-style-type: none;
  margin: 10px 0;
  font-size: 16px;
  height: 20px;
  line-height: 20px;
  font-weight: 300;
}
.ftr .ftr_content .fc_right .fc_service li:first-child {
  height: 22px;
  font-size: 16px;
  line-height: 22px;
  margin: 0 0 20px 0;
}
.ftr .ftr_content .fc_right .fc_service li a {
  color: #333;
}
.ftr .ftr_content .fc_right .fc_service li a:hover {
  color: #5280ff;
}
.ftr .ftr_content .fc_right .fc_service li:hover {
  color: #5280ff;
}
.ftr .ftr_content .fc_right .fc_service .title {
  color: #333 !important;
}
.ftr .ftr_content .fc_right .fc_solution {
  float: left;
  list-style-type: none;
  margin: 0;
  padding: 0 100px 0 0;
}
.ftr .ftr_content .fc_right .fc_solution li {
  list-style-type: none;
  margin: 10px 0;
  font-size: 16px;
  height: 20px;
  line-height: 20px;
  font-weight: 300;
}
.ftr .ftr_content .fc_right .fc_solution li:first-child {
  height: 22px;
  font-size: 16px;
  line-height: 22px;
  margin: 0 0 20px 0;
}
.ftr .ftr_content .fc_right .fc_solution li a {
  color: #333;
}
.ftr .ftr_content .fc_right .fc_solution li a:hover {
  color: #5280ff;
}
.ftr .ftr_content .fc_right .fc_solution li:hover {
  color: #5280ff;
}
.ftr .ftr_content .fc_right .fc_solution .title {
  color: #333 !important;
}
.ftr .ftr_content .fc_right .fc_team {
  float: left;
  list-style-type: none;
  margin: 0;
  padding: 0 100px 0 0;
}
.ftr .ftr_content .fc_right .fc_team li {
  list-style-type: none;
  margin: 10px 0;
  font-size: 16px;
  height: 20px;
  line-height: 20px;
  font-weight: 300;
}
.ftr .ftr_content .fc_right .fc_team li:first-child {
  height: 22px;
  font-size: 16px;
  line-height: 22px;
  margin: 0 0 20px 0;
}
.ftr .ftr_content .fc_right .fc_team li a {
  color: #333;
}
.ftr .ftr_content .fc_right .fc_team li a:hover {
  color: #5280ff;
}
.ftr .ftr_content .fc_right .fc_team li:hover {
  color: #5280ff;
}
.ftr .ftr_content .fc_right .fc_team .title {
  color: #333 !important;
}
.ftr .ftr_content .fc_right .fc_contact_us {
  float: left;
  list-style-type: none;
  margin: 0;
}
.ftr .ftr_content .fc_right .fc_contact_us li {
  list-style-type: none;
  margin: 10px 0;
  font-size: 16px;
  height: 20px;
  line-height: 20px;
  font-weight: 300;
}
.ftr .ftr_content .fc_right .fc_contact_us li:first-child {
  height: 22px;
  font-size: 16px;
  line-height: 22px;
  margin: 0 0 20px 0;
}
.ftr .ftr_content .fc_right .fc_contact_us li a {
  color: #333;
}
.ftr .ftr_content .fc_right .fc_contact_us li a:hover {
  color: #5280ff;
}
.ftr .ftr_content .fc_right .fc_contact_us li:hover {
  color: #5280ff;
}
.ftr .ftr_content .fc_right .fc_contact_us .title {
  color: #333 !important;
}
.ftr .ftr_content .fc_right .fc_contact_us a {
  text-decoration: none;
}
.ftr .ftr_bottom {
  font-weight: 300;
  display: flex;
  justify-content: space-between;
  height: 200px;
  font-size: 16px;
  width: 1200px;
  color: #333;
}
.ftr .ftr_bottom .friendly_link {
  font-size: 16px;
  font-weight: 300;
  padding-top: 30px;
  color: #333;
}
.ftr .ftr_bottom .friendly_link span:first-child {
  margin-left: 0;
}
.ftr .ftr_bottom .friendly_link span {
  margin-left: 20px;
}
.ftr .ftr_bottom .friendly_link span a:hover {
  color: #5280ff !important;
}
.ftr .ftr_bottom .company_info {
  padding-top: 20px;
}
.ftr .ftr_bottom .company_icp {
  display: flex;
  padding-top: 20px;
}
.ftr .ftr_bottom .company_icp span {
  margin-left: 5px;
}
.ftr .ftr_bottom .company_icp span a:hover {
  color: #5280ff !important;
}
.ftr .ftr_bottom .company_icp .info_icp {
  margin-right: 40px;
  margin-left: 2px;
}
.ftr .ftr_bottom .company_icp .info_icp a:hover {
  color: #5280ff !important;
}
.ftr .ftr_bottom .company_icp .info_icp_link {
  cursor: pointer;
}
.ftr .ftr_bottom .wechat_logo {
  line-height: 165px;
}
.ftr .ftr_bottom .wechat_logo img {
  width: 108px;
  height: 108px;
}
.ftr .ftr_bottom img {
  height: 20px;
  width: 20px;
  margin: 0 10px 0 0;
}
.ftr .ftr_bottom span a {
  color: #333 !important;
  cursor:pointer;
}
.title {
  font-size: 20px !important;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-weight: 500 !important;
  color: #fff !important;
}
.cold8 {
  color: #333;
  cursor: pointer;
}
.cold8 a:hover {
  color: #5280ff;
}


</style>

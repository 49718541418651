<template>
  <div class="homePage-container" ref="homeContainer">
  <home-header
      @goToWebApplication="goToWebApplication"
      @navChange="navChangeHandler"
      :current-nav-val="currentNavVal"
      :nav-items="navItems"
  ></home-header>

  <div class="jsx-3881104260 index">
    <section class="jsx-3784957195 banner">
      <div class="jsx-3784957195 banner_top banner_left gettop">MetaGo
        <img src="../../assets/images/tm.png" alt="" class="jsx-3784957195">
      </div>
      <div @click="goDemoVideo()" style="display:inline-block;position:absolute;top:150px;left:266px;cursor:pointer" class="jsx-3784957195">
        <img src="../../assets/images/play.png"  style="width: 53px;height: 53px" alt="" class="jsx-3784957195">
      </div>
      <div class="jsx-3784957195 banner_topmid banner_left">更简单，更强大</div>
      <div class="jsx-3784957195 banner_topmid banner_left banner_normal">数字时代的合同管理平台</div>
      <div class="jsx-3784957195 banner_mid banner_left">
        <div class="jsx-3784957195 bmmw">&nbsp;</div>
        <div class="jsx-3784957195 bmmw"></div>
      </div>
      <div class="jsx-3784957195 banner_box"><img src="../../assets/images/productBanner.png" width="552px"
                                                  class="jsx-3784957195 banner_img" alt=""></div>
      <div class="jsx-3784957195 try_wrap banner_left">
        <button class="jsx-3784957195" @click="goToWebApplication">进入网页版</button>
        <button class="jsx-3784957195 video" @click="registerAccount">免费注册</button>
      </div>
    </section>
  </div>

  <div class=" fir_iteration">

    <div
        class=" commonScenarios" >敏捷、精确、智能化
    </div>
    <div class="wp-block-columns ccst-flex-vertical-top ccst-flex-wrap ccst-flex-row-6-3-2">
      <div class="wp-block-column ccst-flex-column ccst-center ccst-flex-centered">
        <div
            class="wp-container-6215d5f6da445 wp-block-group ccst-flex-centered ccst-rounded-element ccst-paddingtop-10 ccst-paddingbottom-10 ccst-paddingleft-10 ccst-paddingright-10 ccst-width-60 ccst-height-60 has-background"
            style="background-color:#e5ecff;border-radius: 6px;">
          <div class="wp-block-group__inner-container">
            <figure class="wp-block-image size-large ccst-width-full ccst-nomargin"><img loading="lazy" width="106"
                                                                                         height="92"
                                                                                         src="../../assets/images/upload.png"
                                                                                         alt="" class="wp-image-11854">
            </figure>
          </div>
        </div>
        <p class="ccst-paragraphe ccst-font-bold ccst-flex-centered ccst-height-60"><strong>一键上传</strong></p>
      </div>
      <div class="wp-block-column ccst-flex-column ccst-center ccst-flex-centered">
        <div
            class="wp-container-6215d5f6da7df wp-block-group ccst-flex-centered ccst-rounded-element ccst-paddingtop-10 ccst-paddingbottom-10 ccst-paddingleft-10 ccst-paddingright-10 ccst-width-60 ccst-height-60 has-background"
            style="background-color:#e5ecff;border-radius: 6px;">
          <div class="wp-block-group__inner-container">
            <figure class="wp-block-image size-large ccst-width-full ccst-nomargin"><img loading="lazy" width="100"
                                                                                         height="100"
                                                                                         src="../../assets/images/template.png"
                                                                                         alt="" class="wp-image-11853">
            </figure>
          </div>
        </div>
        <p class="ccst-paragraphe ccst-font-bold ccst-flex-centered ccst-height-60"><strong><strong>智能模板</strong></strong></p>
      </div>
      <div class="wp-block-column ccst-flex-column ccst-center ccst-flex-centered">
        <div
            class="wp-container-6215d5f6dac8b wp-block-group ccst-flex-centered ccst-rounded-element ccst-paddingtop-10 ccst-paddingbottom-10 ccst-paddingleft-10 ccst-paddingright-10 ccst-width-60 ccst-height-60 has-background"
            style="background-color:#e5ecff;border-radius: 6px;">
          <div class="wp-block-group__inner-container">
            <figure class="wp-block-image size-large ccst-width-full ccst-nomargin"><img loading="lazy" width="102"
                                                                                         height="102"
                                                                                         src="../../assets/images/partner.png"
                                                                                         alt="" class="wp-image-11852">
            </figure>
          </div>
        </div>
        <p class="ccst-paragraphe ccst-font-bold ccst-flex-centered ccst-height-60"><strong><strong>协同编辑</strong></strong></p>
      </div>
      <div class="wp-block-column ccst-flex-column ccst-center ccst-flex-centered">
        <div
            class="wp-container-6215d5f6db3c1 wp-block-group ccst-flex-centered ccst-rounded-element ccst-paddingtop-10 ccst-paddingbottom-10 ccst-paddingleft-10 ccst-paddingright-10 ccst-width-60 ccst-height-60 has-background"
            style="background-color:#e5ecff;border-radius: 6px;">
          <div class="wp-block-group__inner-container">
            <figure class="wp-block-image size-large ccst-width-full ccst-nomargin"><img loading="lazy" width="108"
                                                                                         height="108"
                                                                                         src="../../assets/images/filter.png"
                                                                                         alt="" class="wp-image-11850">
            </figure>
          </div>
        </div>
        <p class="ccst-paragraphe ccst-font-bold ccst-flex-centered ccst-height-60"><strong><strong>自动抽取</strong></strong></p>
      </div>
      <div class="wp-block-column ccst-flex-column ccst-center ccst-flex-centered">
        <div
            class="wp-container-6215d5f6db739 wp-block-group ccst-flex-centered ccst-rounded-element ccst-paddingtop-10 ccst-paddingbottom-10 ccst-paddingleft-10 ccst-paddingright-10 ccst-width-60 ccst-height-60 has-background"
            style="background-color:#e5ecff;border-radius: 6px;">
          <div class="wp-block-group__inner-container">
            <figure class="wp-block-image size-large ccst-width-full ccst-nomargin"><img loading="lazy" width="120"
                                                                                         height="120"
                                                                                         src="../../assets/images/time.png"
                                                                                         alt="" class="wp-image-11849">
            </figure>
          </div>
        </div>
        <p class="ccst-paragraphe ccst-font-bold ccst-flex-centered ccst-height-60"><strong><strong>履约提醒</strong></strong></p>
      </div>
    </div>
<!--    <div id="demo-video-area" style="padding-top:90px">-->
<!--      <video id="demo-video" src="https://uranus-static.oss-cn-beijing.aliyuncs.com/metago/demo1.mp4" style="width: 1000px" controls></video>-->
<!--    </div>-->
    <div class=" fir_tabs_box">
        <div class="poster-container">
          <div class="poster-item">
              <img class="poster-item-bg" src="../../assets/images/homePage_1.jpg">
              <div class="poster-item-mrk">
                <p class="poster-mrk-mainTitle">一键上传</p>
                <p class="poster-mrk-second">老合同或新合同，扫描件或WORD文档，都可以</p>
                <p class="poster-mrk-content">用最简单的操作，实现对企业时间线上所有合同的数字化，并实质性节<wbr>约合同格式转换的成本。</p>
              </div>

          </div>
          <div class="poster-item">
            <img class="poster-item-bg" src="../../assets/images/homePage_2.jpg">
            <div class="poster-item-mrk">
              <p class="poster-mrk-mainTitle">智能模板</p>
              <p class="poster-mrk-second">自助创建，按需更新</p>
              <p class="poster-mrk-content">AI赋能合同起草，在提供明确指引的同时，充分帮助合同撰写者根据实际情况发挥创造性，让模板科学、规范，但不僵化，真正实现合同模板量身制作。</p>
            </div>

          </div>
          <div class="poster-item">
            <img class="poster-item-bg" src="../../assets/images/homePage_3.jpg">
            <div class="poster-item-mrk">
              <p class="poster-mrk-mainTitle">协同编辑</p>
              <p class="poster-mrk-second">团队成员、第三方可共同在线编辑</p>
              <p class="poster-mrk-content">为跨企业的合同管理者搭建实时在线协作平台，减少合同的低效流转。编辑过程全留痕、可溯源，兼顾便捷高效和稳定安全，打造升级版“修订模式”。</p>
            </div>

          </div>

          <div class="poster-item">
            <img class="poster-item-bg" src="../../assets/images/homePage_4.jpg">
            <div class="poster-item-mrk">
              <p class="poster-mrk-mainTitle">自动抽取</p>
              <p class="poster-mrk-second">关键信息，一目了然</p>
              <p class="poster-mrk-content">全面检视合同内容，快速精准突出重点，帮助用户第一时间了解每份合同的“骨架”、建立后续管理合同的“提纲”。</p>
            </div>
          </div>
          <div class="poster-item">
            <img class="poster-item-bg" src="../../assets/images/homePage_5.jpg">
            <div class="poster-item-mrk">
              <p class="poster-mrk-mainTitle">履约提醒</p>
              <p class="poster-mrk-second">重要时点，提前提醒</p>
              <p class="poster-mrk-content">把对合同履行至关重要的时间点，用最直观的方式--日历，呈现出来，并提前提醒。让合同管理者面对“DDL”时打出提前量，化被动为主动。</p>
            </div>


          </div>
        </div>
    </div>
    <div class=" fir_crowd">
      <div class=" fir_crowd_tit">管合同，用MetaGO
      </div>
      <div class=" fir_crowd_container">
        <div class=" fir_crowd_list"><img src="../../assets/images/legal.png" alt=""
                                                        class="">
          <h3 class="">公司法务</h3>
          <p class="">减少重复工作，轻松管理模板</p>
          <p class="">任意版本对比，全流程留痕</p></div>
        <div class=" fir_crowd_list fir_crowd_list_padding"><img
            src="../../assets/images/ceo.png" alt="" class="">
          <h3 class="">企业领导</h3>
          <p class="">企业动态尽在掌握</p>
          <p class="">提升效率，降低履约成本，避免不必要的损失</p></div>
        <div class=" fir_crowd_list"><img src="../../assets/images/business-personel.png" alt=""
                                                        class="">
          <h3 class="">业务人员</h3>
          <p class="">一键生成，协同编辑，加速达成交易</p>
          <p class="">自动续约提醒，抓住每一次交易机会</p></div>
      </div>
    </div>
  </div>
    <Mask
        :display="showLoginComponent ? 'block':'none'"
        :z-index="1001"
    >
      <div class="loginComponentWrap insetCenter">
        <AuthorizeUserComponent
            @loginSuccess="resolveAfterSigning"
            @signupSuccess="resolveAfterSigning"
            :mission-type="missionType"
            :showCloseBtn="true"
            @showCloseBtnClicked="showLoginComponent = false"
            v-if="showLoginComponent"
        ></AuthorizeUserComponent>
      </div>

    </Mask>

  <home-footer ref="footer"></home-footer>
  </div>
</template>

<script>

import HomeHeader from "../../components/home/HomeHeader";
import HomeFooter from "../../components/home/HomeFooter";
import AuthorizeUserComponent from "../../components/account/AuthorizeUserComponent";
import {getMyInfo} from "../../api/api";
import {colorLog, deleteCookie, DomEventListenerManager, getScrollParentElement} from "../../util";
import Mask from "../../components/universalUI/Mask";
import anime from "../../assets/js/anime.es";
import {toRaw} from "vue";
import {config, DEPLOY_TYPE} from '../../config'

export default {
  name: 'HomePage',
  data() {
    return {
      navItems:[
        {value:'产品',node:null},
        {value:'关于',node:'this.$refs.footer.$el.querySelector(\'.ftr\')'}
      ],
      currentNavVal:'产品',
      showLoginComponent:this.$route.params.showLoginComponent ? this.$route.params.showLoginComponent : false,
      missionType: 'login',
      navOffset:[0,.2],//导航条滚动到最终停留的元素时,浏览器顶部距离目标元素的距离比例(和屏幕高度相乘)
      domEventListenerManager:new DomEventListenerManager(),
      scrolling:false,
    }
  },
  created(){

  },
  beforeUnmount() {
    this.domEventListenerManager.destroy();
  },
  mounted(){
    let homeContainer = this.$refs.homeContainer;

    //将node赋上对应的dom值
    for(let navItem of this.navItems){
      if(navItem.node){
        navItem.node = eval(navItem.node);
      }
    }

    //导航栏监听事件
    this.domEventListenerManager.registerListener(document,'scroll',()=>{
      if(this.scrolling) return;
      let scrollerHeight = homeContainer.scrollHeight;
      if(window.scrollY + window.innerHeight + 10 >= scrollerHeight){
        this.currentNavVal =  this.navItems[this.navItems.length - 1].value;
      }else{
        if(window.scrollY + window.innerHeight){
          let end = 0;
          let cover = true;
          while(cover && end < this.navItems.length){
            let node = this.navItems[end].node;
             if(window.scrollY +  window.innerHeight / 2 >= (node ? node.offsetTop : 0)){
               cover = true;
               end++;
             }else{
               cover = false;
             }
          }
          this.currentNavVal = this.navItems[end - 1].value;
      }
      }
    })

    //清除测试环境clm.metago.cn域名下的cookie, 正式上线后该逻辑可清除
    function clearDomainCookie(domain){
      deleteCookie('sid',domain);
      deleteCookie('uid',domain);
    }
    ['.clm.metago.cn','.testmetago.metasotalaw.cn','.clm.metasotalaw.cn'].forEach(domain => clearDomainCookie(domain));
  },
  methods:{
    navChangeHandler(nav){
      const scroller = getScrollParentElement(this.$el);
      let offset;
      let targetOffsetTop;
      switch (nav){
        case this.navItems[0].value: //产品
          offset = this.navOffset[0];
          targetOffsetTop = 0;
          break;
        case this.navItems[1].value://关于
          offset = this.navOffset[1];
          targetOffsetTop = this.navItems[1].node.offsetTop;
          break;
      }
      this.scrolling = true;
      this.currentNavVal = nav;

      anime({
        targets:scroller,
        scrollTop: targetOffsetTop - offset * scroller.clientHeight,
        easing:'easeInOutQuart',
        during:2000,
        changeComplete:  ()=>{
          this.scrolling = false;
        }
      });
    },
    goDemoVideo(){
      document.querySelector("#demo-video-area").scrollIntoView(true);
      document.querySelector("#demo-video").play();
    },
    checkLoginState(callback){
      getMyInfo().then(userInfoObj => {
        if (userInfoObj.data.code === 401 ||userInfoObj.data.code === 402){
          //未登陆
          if(callback){
            callback(false);
          }
        }else{
          if(callback){
            callback(true);
          }
        }
      })
    },
    resolveAfterSigning(){
      if(this.$route.query.redirect) {
        this.jump(this.$route.query.redirect)
      }
      else{
        this.$router.push('/index');
      }
    },
    goToWebApplication(){
      this.checkLoginState((success)=>{
        if(success){
          this.$router.push({
            name:'contractIndex'
          })
        }else{
          this.showLoginComponent = true;
        }
      })
    },
    registerAccount(){
      this.goToWebApplication();

    },
    jump(url){
      if(url.startsWith("http")){
        window.location.href= url;
      }
      this.$router.push(url);
    }
  },
  components: {
    Mask,
    AuthorizeUserComponent,
    HomeHeader,
    HomeFooter
  },
}


</script>
<style #scope>
.loginComponentWrap{
  position: absolute;
}

.homePage-container{
  height:100%;
  margin: 0;
  padding: 0;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  font-variant: tabular-nums;
  line-height: 1.5;
  background-color: #fff;
  font-feature-settings: 'tnum';

  overflow: auto !important;
  width: 100% !important;
}
.homePage-container ul,
.homePage-container li{
  margin: 0;
  padding: 0;
}

.homePage-container *,
.homePage-container *::before,
.homePage-container *::after {
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 5px;
  background-color: #fff;
}
::-webkit-scrollbar-track {
  border-radius: 5px;
}
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #ddd;
}

body {

}

body {

}


.edu_banner_line1.jsx-3784957195 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.edu_banner_line2.jsx-3784957195 {
  margin-bottom: 80px;
}

.banner.jsx-3784957195 {
  text-align: center;
  width: 1000px;
  margin: 70px auto 0;
  max-height: 600px;
  min-height: 550px;
  box-sizing: border-box;
  padding-top: 150px;
  position: relative;
}
.banner_left.jsx-3784957195 {
  text-align: left;
  position: relative;
  padding: 0 0 0 5.625%;
}

.recommend.jsx-3784957195 {
  display: inline-block;
  position: absolute;
  top: -33px;
  left: 285px;
  width: 62px;
  height: 92px;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 100% 100%;
  text-align: center;
}

.recommend.jsx-3784957195 img.jsx-3784957195 {
  height: auto;
  width: 100%;
}

.banner_normal.jsx-3784957195 {
  font-size: 20px !important;
  font-weight: 400 !important;
  margin-top: 10px;
  color: #333;
}

.banner_left.jsx-3784957195 .video.jsx-3784957195 {
  width: 140px;
  height: 48px;
  border-radius: 6px;
  background-color: #ffffff;
  color: #5280ff;
  border: 1px solid #5280ff;
  font-size: 20px;
  margin-left: 28px;
}

.banner_top.jsx-3784957195 {
  font-size: 48px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-weight: bold;
  color: #333;
  line-height: 60px;
  margin-bottom: 5px;
}

.banner_topmid.jsx-3784957195 {
  font-size: 48px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-weight: bold;
  color: #333;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.banner_recommend.jsx-3784957195 {
  margin-left: 3px;
}

.service_logo.jsx-3784957195 {
  width: 240px;
  margin: 0 0 20px 0;
}

.banner_mid.jsx-3784957195 {
  margin: 20px auto 20px;
  font-size: 24px;
  color: #262626;
  -webkit-letter-spacing: 1px;
  -moz-letter-spacing: 1px;
  -ms-letter-spacing: 1px;
  letter-spacing: 1px;
}

.banner_mid.jsx-3784957195 .bmmw.jsx-3784957195 {
  font-size: 20px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-weight: 400;
  color: #333333;
  line-height: 20px;
}

.banner_mid_service.jsx-3784957195 {
  height: 50px;
  font-size: 24px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-weight: normal;
  color: rgba(255, 255, 255, 1);
  line-height: 50px;
  font-weight: 400;
}

.banner_mid_service_bottom.jsx-3784957195 {
  height: 32px;
  font-size: 24px;
  color: rgba(255, 255, 255, 1);
  line-height: 32px;
  -webkit-letter-spacing: 1px;
  -moz-letter-spacing: 1px;
  -ms-letter-spacing: 1px;
  letter-spacing: 1px;
  margin: 0 0 39px 0;
  font-weight: 400;
}

.banner_mid_solution.jsx-3784957195 {
  height: 32px;
  font-size: 24px;
  font-weight: normal;
  color: rgba(255, 255, 255, 1);
  line-height: 32px;
  -webkit-letter-spacing: 1px;
  -moz-letter-spacing: 1px;
  -ms-letter-spacing: 1px;
  letter-spacing: 1px;
  margin: 14px 0 30px 0;
}

.banner_mid_price.jsx-3784957195 {
  height: 32px;
  font-size: 42px;
  font-weight: 400;
  color: #333;
  line-height: 32px;
  -webkit-letter-spacing: 1px;
  -moz-letter-spacing: 1px;
  -ms-letter-spacing: 1px;
  letter-spacing: 1px;
  margin: 14px 0 60px 0;
}

.banner_mid_link.jsx-3784957195 {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  margin-bottom: 5px;
  color: #333;
  line-height: 40px;
  font-weight: normal;
}

.banner_mid_p.jsx-3784957195 {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  margin-top: 24px;
  color: #333;
  line-height: 40px;
  font-weight: bold;
  font-size: 48px;
}

.banner_join.jsx-3784957195 {
  height: 60px;
  font-size: 44px;
  color: rgba(255, 255, 255, 1);
  line-height: 60px;
  -webkit-letter-spacing: 1px;
  -moz-letter-spacing: 1px;
  -ms-letter-spacing: 1px;
  letter-spacing: 1px;
  margin: 80px 0 0 0;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  position: absolute;
  top: 70px;
  left: 0;
}

.banner_join_bottom.jsx-3784957195 {
  margin: 80px auto 0;
  width: 1200px;
  height: 114px;
  font-size: 18px;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  line-height: 40px;
  -webkit-letter-spacing: 1px;
  -moz-letter-spacing: 1px;
  -ms-letter-spacing: 1px;
  letter-spacing: 1px;
  opacity: 0.9;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  position: absolute;
  top: 275px;
  left: 0;
}

.banner_box {
  width: 552px;
  height: auto;
  position: absolute;
  right: 0;
  top: 105px;
}
.banner_box .banner_img {
  width: 90%;
  height: auto;
}
.banner_box .banner_hover {
  width: 552px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 11;
  cursor: pointer;
}
.banner_box .banner_hover img {
  display: block;
  width: 65px;
  height: 65px;
  position: absolute;
  left: 46%;
  top: 46%;
  transform: translate(-50%, -50%);
}

.try_wrap.jsx-3784957195 button.jsx-3784957195 {
  -webkit-letter-spacing: 1px;
  -moz-letter-spacing: 1px;
  -ms-letter-spacing: 1px;
  letter-spacing: 1px;
  padding: 0;
  outline: 0;
  border: 0;
  -webkit-text-decoration: none;
  text-decoration: none;
  border: 1px solid #5280ff !important;
  background: none;
  cursor: pointer;
  width: 140px;
  height: 48px;
  background: #5280ff;
  border-radius: 6px;
  font-size: 20px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  color: #ffffff;
  line-height: 20px;
}

.solutionlog.jsx-3784957195 {
  font-size: 52px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-weight: bold;
  color: #333;
  line-height: 60px;
}

.banner_price_tips.jsx-3784957195 {
  position: fixed;
  left: 0;
  top: 70px;
  width: 100%;
  height: 40px;
  line-height: 40px;
  background: #b5e6ff;
  text-align: center;
  color: #333333;
  font-size: 16px;
  font-weight: normal;
  z-index: 1000;
  font-family: Avenir, Helvetica, Arial, sans-serif;
}

.banner_price_tips.jsx-3784957195 a.jsx-3784957195 {
  font-weight: normal;
  padding-left: 10px;
  cursor: pointer;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: #5280ff;
}

.banner_price_tips.jsx-3784957195 a.jsx-3784957195:hover {
  color: #5280ff;
}

.pricelog.jsx-3784957195 {
  font-size: 52px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-weight: bold;
  line-height: 60px;
  color: #333;
}

.newclolr.jsx-3784957195 button.jsx-3784957195 {
  width: 140px;
  height: 48px;
  color: #fff;
  background: #5280ff;
  margin-top: 70px;
  border: 1px solid #5280ff !important;
  font-size: 20px;
}

.lesp.jsx-3784957195 {
  -webkit-letter-spacing: 2.3px;
  -moz-letter-spacing: 2.3px;
  -ms-letter-spacing: 2.3px;
  letter-spacing: 2.3px;
}

.banner_logo.jsx-3784957195 {
  position: absolute;
  right: 10px;
  top: 0px;
  width: 510px;
  height: auto;
  margin-top: -78px;
}

.gettop.jsx-3784957195 {
  position: relative;
}

.gettop.jsx-3784957195 img.jsx-3784957195 {
  height: 10px;
  position: absolute;
  top: 8px;
  margin-left: 6px;
}

.videobtn.jsx-3784957195 {
  margin-left: 20px;
  height: 56px;
  margin-top: -5px;
  cursor: pointer;
}

.bmvideo.jsx-3784957195 {
  width: 864px;
  height: 483px;
  position: fixed;
  left: 50%;
  top: 50%;
  margin-top: -243px;
  margin-left: -432px;
  z-index: 1002;
  outline: 0;
}

.bmvideoMask.jsx-3784957195 {
  position: fixed;
  left: 0%;
  top: 0%;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  z-index: 1001;
  overflow: hidden;
  opacity: 0.6;
}

.price_float.jsx-3784957195 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-evenly;
  padding-top: 100px;
}

.price_float.jsx-3784957195 .price_established.jsx-3784957195 {
  width: 367px;
  height: 380px;
  background: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 2px 20px 0px #999;
  text-align: center;
}

.price_float.jsx-3784957195 .price_established.jsx-3784957195 .price_title.jsx-3784957195 {
  width: 367px;
  height: 16px;
  background: #102896;
  border-radius: 5px 5px 0px 0px;
}

.price_float.jsx-3784957195 .price_activity.jsx-3784957195 {
  width: 80px;
  height: 28px;
  background: #eb2200;
  border-radius: 0px 0px 6px 0px;
  color: #ffffff;
  line-height: 28px;
}

.price_float.jsx-3784957195 .price_established.jsx-3784957195 .price_detail.jsx-3784957195 {
  padding: 10px 0;
}

.price_float.jsx-3784957195 .price_established.jsx-3784957195 .price_detail.jsx-3784957195 .price_period.jsx-3784957195 {
  font-size: 36px;
  font-weight: 500;
  color: #333333;
  line-height: 36px;
  padding-bottom: 80px;
}

.price_float.jsx-3784957195 .price_established.jsx-3784957195 button.jsx-3784957195 {
  width: 200px;
  height: 56px;
  background: #5280ff;
  border-radius: 5px;
  font-size: 20px;
  color: #ffffff;
}

.price_float.jsx-3784957195 .price_price.jsx-3784957195 {
  font-size: 36px;
  font-weight: 500;
  color: #5280ff;
  line-height: 36px;
  margin-bottom: 20px;
}

.price_float.jsx-3784957195 .price_price.jsx-3784957195 span.jsx-3784957195 {
  font-size: 20px;
}

.price_float.jsx-3784957195 .price_desc.jsx-3784957195 {
  font-size: 20px;
  font-weight: 300;
  color: #999999;
  line-height: 16px;
  margin-bottom: 40px;
}

.index_banner.jsx-3784957195 {
  top: 110px;
}


.member_rights_list_ctn.jsx-2544873954 div.jsx-2544873954 {
  /*background: url('../static/purchase/yes.png') right 8px no-repeat;*/
  background-size: 12px 12px;
}

.wechat_msg.jsx-2544873954 {
  padding-left: 30px;
  /*background: url('../static/purchase/wechat.svg') 0px 0px no-repeat;*/
  background-size: 20px 20px;
}

.top.jsx-3881104260 {
  height: 600px;
  width: 100%;
  /*background: url("../static/banner_.svg") center center no-repeat;*/
  background-size: cover;
}

.index.jsx-3881104260 {
  width: 100%;
  background-size: cover;
}

.fir_iteration {
  font-size: 10px;

  width: 100em;
  height: auto;
  overflow: hidden;
  margin: 0 auto;
}
.fir_iteration .commonScenarios {
  font-size: 36px;
  color: #333;
  font-weight: 500;
  line-height: 67px;
  padding: 90px 0;
  text-align: center;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-size: 60px 60px;
  background-position: 55.8% 49%;
  margin-left: 26px;
}

.fir_iteration .tabLi {
  overflow: hidden;
}
.fir_iteration .tabLi ul {
  list-style: none;
}
.fir_iteration .tabLi ul li:nth-child(-n+4) {
  padding-right: 164px;
}
.fir_iteration .tabLi ul li {
  float: left;
}
.fir_iteration .tabLi ul li img {
  width: 96px;
  height: 96px;
}
.fir_iteration .tabLi ul li p {
  color: #333;
  text-align: center;
  height: 28px ;
  line-height: 28px;
  margin-top: 24px;
  font-size: 18px;
}
.fir_tabs_box {
  width: 100%;
  height: auto;
  margin-top: 70px;
  position: relative;
}
.fir_tabs_box .ant-tabs-bar {
  border: none;
}
.fir_tabs_box .ant-tabs-nav-wrap {
  width: 80%;
  margin-left: 10%;
}
.fir_tabs_box .ant-tabs-nav-wrap .ant-tabs-nav {
  width: 100%;
}
.fir_tabs_box .ant-tabs-nav-wrap .ant-tabs-tab {
  width: 10%;
  height: 60px;
  line-height: 60px;
  text-align: center;
  font-size: 24px;
  color: #333 !important;
  padding: 0;
  margin: 0 5%;
  font-weight: 400;
}
.fir_tabs_box .ant-tabs-nav-wrap .ant-tabs-tab.ant-tabs-tab-active {
  font-weight: 500;
  color: #333;
}
.fir_tabs_box .ant-tabs-nav-wrap .ant-tabs-ink-bar {
  height: 4px;
}
.fir_tabs_container {
  width: 100%;
  height: auto;
  overflow: hidden;
  padding: 20px 0 0;
  box-sizing: border-box;
}
.fir_tabs_container .fir_tabs_container_img {
  width: 100%;
  height: auto;
  padding: 0 55px;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-size: 235px 235px;
  background-position: 100% 100%;
  text-align: center;
}
.fir_tabs_container .fir_tabs_container_img img {
  width: 70%;
  height: auto;
}
.fir_tabs_container .fir_tabs_container_fontList {
  width: 100%;
  height: auto;
  padding: 30px 90px 0 220px;
  box-sizing: border-box;
}
.fir_tabs_container .fir_tabs_container_fontList p {
  width: 100%;
  height: auto;
  font-size: 16px;
  color: #333;
  line-height: 33px;
  display: flex;
  align-items: center;
}
.fir_tabs_container .fir_tabs_container_fontList p em {
  width: 10px;
  height: 10px;
  background-color: #5280ff;
  border-radius: 50%;
  margin-right: 16px;
}
.fir_crowd {
  width: 100%;
  height: auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.fir_crowd .fir_crowd_tit {
  font-size: 36px;
  color: #333;
  font-weight: 500;
  line-height: 67px;
  padding: 145px 20px;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-size: 60px 60px;
  background-position: 100% 49%;
}
.fir_crowd .fir_crowd_container {
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
}
.fir_crowd .fir_crowd_container .fir_crowd_list {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}
.fir_crowd .fir_crowd_container .fir_crowd_list img {
  width: 100%;
  height: auto;
}
.fir_crowd .fir_crowd_container .fir_crowd_list h3 {
  font-size: 18px;
  color: #333;
  line-height: 28px;
  margin: 42px 0 28px 0;
}
.fir_crowd .fir_crowd_container .fir_crowd_list p {
  font-size: 18px;
  color: #333;
  line-height: 20px;
  padding: 5px 0;
  box-sizing: border-box;
}
.fir_crowd .fir_crowd_container .fir_crowd_list_padding {
  padding: 0 60px;
}
.fir_plugin {
  width: 1200px;
  height: auto;
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.fir_plugin .fir_plugin_tit {
  font-size: 32px;
  color: #333;
  line-height: 45px;
  padding: 145px 0;
  box-sizing: border-box;
}
.fir_plugin .fir_plugin_container {
  width: 100%;
  height: auto;
  border: 2px solid #eaeaea;
  border-radius: 28px;
  overflow: hidden;
  padding: 0 92px;
  box-sizing: border-box;
}
.fir_plugin .fir_plugin_container .fir_plugin_container_list:last-child {
  border-bottom: none;
}
.fir_plugin .fir_plugin_container .fir_plugin_container_list {
  width: 100%;
  height: auto;
  display: flex;
  align-items:flex-start;
  border-bottom: 1px dashed #dbdbdb;
  padding: 35px 0;
  box-sizing: border-box;
}
.fir_plugin .fir_plugin_container .fir_plugin_container_list .fir_plugin_logo {
  width: 60px;
  height: 60px;
  flex: 0 0 60px;
}
.fir_plugin .fir_plugin_container .fir_plugin_container_list .fir_plugin_container_con {
  flex: 1;
  padding: 0 10px 0 32px;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.fir_plugin .fir_plugin_container .fir_plugin_container_list .fir_plugin_container_con h2 {
  width: 100%;
  font-size: 20px;
  color: #333;
  font-weight: bold;
  display: flex;
  align-items: center;
}
.fir_plugin .fir_plugin_container .fir_plugin_container_list .fir_plugin_container_con h2 i {
  font-style: normal;
  width: 82px;
  height: 24px;
  flex: 0 0 auto;
  line-height: 22px;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  border: 1px solid #ff4242;
  border-radius: 2px;
  color: #ff4242;
}
.fir_plugin .fir_plugin_container .fir_plugin_container_list .fir_plugin_container_con p {
  font-size: 16px;
  color: #333;
  line-height: 22px;
  margin: 10px 0 16px;
}
.fir_plugin .fir_plugin_container .fir_plugin_container_list .fir_plugin_container_con p i {
  margin-right: 32px;
  font-style: normal;
}
.fir_plugin .fir_plugin_container .fir_plugin_container_list .fir_plugin_container_con em {
  font-size: 16px;
  color: #666;
  line-height: 20px;
  font-style: normal;
}
.fir_plugin .fir_plugin_container .fir_plugin_container_list .fir_plugin_container_con em u {
  cursor: pointer;
}
.fir_plugin .fir_plugin_container .fir_plugin_container_list .fir_plugin_btn {
  width: 136px;
  height: 42px;
  flex: 0 0 136px;
  line-height: 40px;
  border: 1px solid #5280ff;
  border-radius: 6px;
  text-align: center;
  color: #5280ff;
  font-size: 16px;
  cursor: pointer;
}

figure {
  position: relative
}

figure figcaption {
  padding: 1.5rem 20px;
  max-width: calc(100% - 40px);
  position: absolute;
  left: 20px;
  bottom: 20px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-style: italic;
  color: #4b5d69;
  background-color: hsla(0, 0%, 100%, .8)
}

img {
  max-width: 100%;
  height: auto
}

.ccst-column-to-simple-tablet > .wp-block-column:first-child {
  padding-right: 20px
}

.wp-block-columns.ccst-column-to-phone-2-3 .wp-block-column {
  -ms-flex-preferred-size: 33.33% !important;
  flex-basis: 33.33% !important
}

.wp-block-columns.ccst-column-to-phone-2-3 .wp-block-column:last-child {
  -ms-flex-preferred-size: 66.66% !important;
  flex-basis: 66.66% !important
}

.wp-block-column.ccst-padding-20-forced {
  padding: 20px !important
}

.ccst-paragraphe {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  line-height: 24px;
  word-break: normal
}

.ccst-paragraphe, .ccst-paragraphe-bold {
  font-size: 16px;
  letter-spacing: .5px;
  color: #555
}

.ccst-center {
  text-align: center
}

.ccst-center, .ccst-center-auto {
  margin: 0 auto
}

.ccst-font-bold {
  font-weight: 600 !important
}

.ccst-width-full {
  width: 100% !important
}

.ccst-flex {
  display: -ms-flexbox !important;
  display: flex !important
}

.ccst-flex-vertical-top {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: flex-start !important;
  align-items: flex-start !important
}

.ccst-flex-wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap
}

.ccst-flex-centered {
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -ms-flex-align: center !important;
  align-items: center !important
}

.ccst-flex-column {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column
}

.ccst-flex-row-6-3-2 {
  position: relative
}

.ccst-flex-row-6-3-2 .wp-block-column {
  z-index: 1
}

.ccst-flex-row-6-3-2:after {
  z-index: 0;
  left: 10%;
  top: calc(3em - 1px);
  border-top: 2px dashed #e5ecff;
  width: 80%;
  height: 0;
  position: absolute;
  content: ""
}

.ccst-width-60 {
  width: 60px
}

.ccst-height-60 {
  height: 60px
}

.ccst-position-relative {
  position: relative
}

.ccst-position-absolute {
  position: absolute
}

.ccst-nomargin {
  margin: 0 !important
}

.ccst-nomargin-top {
  margin-top: 0 !important
}

.ccst-nomargin-bottom {
  margin-bottom: 0 !important
}

.ccst-nomargin-left {
  margin-left: 0 !important
}

.ccst-nomargin-right {
  margin-right: 0 !important
}

.ccst-marginbottom-10 {
  margin-bottom: 10px !important
}

.ccst-marginleft-10 {
  margin-left: 10px !important
}

.ccst-marginright-10 {
  margin-right: 10px !important
}

.ccst-padding-10 {
  padding: 10px
}

.ccst-paddingtop-10 {
  padding-top: 10px !important
}

.ccst-paddingbottom-10 {
  padding-bottom: 10px !important
}

.ccst-paddingleft-10 {
  padding-left: 10px !important
}

.ccst-paddingright-10 {
  padding-right: 10px !important
}

.fir_tabs_box {
  width: 100%;
  height: auto;
  margin-top: 70px;
  position: relative;
}
.fir_tabs_box .ant-tabs-bar {
  border: none;
}
.fir_tabs_box .ant-tabs-nav-wrap {
  width: 80%;
  margin-left: 10%;
}
.fir_tabs_box .ant-tabs-nav-wrap .ant-tabs-nav {
  width: 100%;
}
.fir_tabs_box .ant-tabs-nav-wrap .ant-tabs-tab {
  width: 10%;
  height: 60px;
  line-height: 60px;
  text-align: center;
  font-size: 24px;
  color: #333 !important;
  padding: 0;
  margin: 0 5%;
  font-weight: 400;
}
.fir_tabs_box .ant-tabs-nav-wrap .ant-tabs-tab.ant-tabs-tab-active {
  font-weight: 500;
  color: #333;
}
.fir_tabs_box .ant-tabs-nav-wrap .ant-tabs-ink-bar {
  height: 4px;
}
.fir_tabs_container {
  width: 100%;
  height: auto;
  overflow: hidden;
  padding: 20px 0 0;
  box-sizing: border-box;
}
.fir_tabs_container .fir_tabs_container_img {
  width: 100%;
  height: auto;
  padding: 0 55px;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-size: 235px 235px;
  background-position: 100% 100%;
  text-align: center;
}
.fir_tabs_container .fir_tabs_container_img img {
  width: 70%;
  height: auto;
}
.fir_tabs_container .fir_tabs_container_fontList {
  width: 100%;
  height: auto;
  padding: 30px 90px 0 220px;
  box-sizing: border-box;
}
.fir_tabs_container .fir_tabs_container_fontList p {
  width: 100%;
  height: auto;
  font-size: 16px;
  color: #333;
  line-height: 33px;
  display: flex;
  align-items: center;
}
.fir_tabs_container .fir_tabs_container_fontList p em {
  width: 10px;
  height: 10px;
  background-color: #5280ff;
  border-radius: 50%;
  margin-right: 16px;
}

.poster-container{
}
.poster-item{
  position: relative;
  text-align: left;
  line-height: 1;
}
.poster-item p{
  margin: 0;

}
.poster-mrk-mainTitle{
  font-size: 2em;
  color:rgb(50,150,250);
  font-weight: bold;
}
.poster-item-mrk{
  position: absolute;
  width: 50%;
  height: auto;
  box-sizing: border-box;
  padding: 0 0 0 5.625%;
  top: 15%;
  left: 0;
  bottom:0;
}
.poster-item .poster-mrk-second{
  font-size: 2em;
  position: relative;
  font-weight: bold;
  color: #000;
  top: 9.4%;
}
.poster-mrk-content{
  position: relative;
  top: 18.2%;
  font-size: 1.40625em;
  line-height: 1.32;
}

</style>

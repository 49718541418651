<template>
  <nav class="nav_wrap">
    <div class="nav_wrap_child">
      <div class="logo_wrap">
        <MetaGoLogo :use-simple="true"></MetaGoLogo>
      </div>
      <div class="nav_item_s" v-for="item in navItems" @click="changeNavVal(item.value)" :class="{'nav_item_active_s':currentNavVal == item.value}">
        {{item.value}}
      </div>
<!--      <span class="ant-dropdown-link ant-dropdown-trigger"><div class="nav_item_s">下载</div></span>-->
<!--      <div class="nav_item_s">定价</div>-->
<!--      <div class="nav_item_s">企业版-->
<!--        <ul>-->
<!--          <li><a href="/solution#mao1">智能编辑器</a></li>-->
<!--          <li><a href="/solution#mao2">PDF解决方案</a></li>-->
<!--          <li><a href="/solution#mao3">见远智能搜索</a></li>-->
<!--        </ul>-->
<!--      </div>-->
      <div class="nav_item_s" @click="$router.push({name:'helpCenter'})"><span>帮助中心</span></div>
      <div class="btn_wrap">
        <div class="btn_wrap_ctn">
          <div class="btn_item"><a @click="$emit('goToWebApplication')">进入网页版</a></div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>

import MetaGoLogo from "../universalUI/MetaGoLogo";
export default {
  name: "HomeHeader",
  components: {MetaGoLogo},
  props: ['navItems','currentNavVal'],
  data: function () {
    return {
    }
  },
  watch:{

  },
  methods: {
    changeNavVal(val){
      this.$emit('navChange',val);
    }
  },
}
</script>

<style scoped>
.nav_wrap{
  text-align: start;
}
/**
 * Remove the border on images inside links in IE 10.
 */
img {
  border-style: none;
}
img {
  vertical-align: middle;
  border-style: none;
}

.nav_wrap_active {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #0006b5;
  z-index: 1000;
}
.nav_wrap {
  background: #fff;
  width: 100%;
  height: 70px;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}
.nav_wrap .logo_wrap {
  height: 70px;
  line-height: 70px;
  width: 187px;
  float: left;
  margin-right: 319px;
}
.nav_wrap .logo_wrap .logo_top {
  height: 33px;
  width: 187px;
  cursor: pointer;
}
.nav_wrap .nav_item {
  height: 70px;
  line-height: 70px;
  margin: 0 0 0 40px;
  font-weight: normal;
  cursor: pointer;
  display: inline-block;
}
.nav_wrap .nav_item:hover {
  color: #5280ff;
}
.nav_wrap .nav_item:hover ul {
  display: none;
}
.nav_wrap .nav_item ul {
  display: none;
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 10px 42px;
  margin: 8px 0px 0 -50px;
}
.nav_wrap .nav_item ul::before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  top: -20px;
  left: 43%;
  border-width: 10px;
  border-style: solid dashed dashed;
  border-color: transparent transparent #fff;
}
.nav_wrap .nav_item ul li {
  list-style-type: none;
  height: 44px;
  font-size: 16px;
  font-weight: 500;
  color: #333333;
  line-height: 44px;
  text-align: center;
}
.nav_wrap .nav_item ul li a {
  text-decoration: none;
  color: #333333;
}
.nav_wrap .nav_item_active:hover {
  color: #5280ff;
}
.nav_wrap .down_s {
  display: none !important;
  width: 1px !important;
}
.nav_wrap .down_m {
  display: flex !important;
}
.nav_wrap .nav_item_s {
  height: 70px;
  line-height: 70px;
  margin: 0 0 0 42px;
  font-weight: normal;
  cursor: pointer;
  display: inline-block;
}
.nav_wrap .nav_item_s a:link {
  color: #333;
}
.nav_wrap .nav_item_s a:visited {
  color: #333;
}
.nav_wrap .nav_item_s span:hover {
  color: #5280ff;
}
.nav_wrap .nav_item_s:hover {
  color: #5280ff;
}
.nav_wrap .nav_item_s:hover ul {
  display: none;
}
.nav_wrap .nav_item_s ul {
  display: none;
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 10px 42px;
  margin: 0px 0px 0 -60px;
}
.nav_wrap .nav_item_s ul::before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  top: -20px;
  left: 43%;
  border-width: 10px;
  border-style: solid dashed dashed;
  border-color: transparent transparent #fff;
}
.nav_wrap .nav_item_s ul li {
  list-style-type: none;
  height: 44px;
  font-size: 16px;
  font-weight: 500;
  color: #333333;
  line-height: 44px;
  text-align: center;
}
.nav_wrap .nav_item_s ul li a {
  text-decoration: none;
  color: #333333;
}
.nav_wrap .nav_item_active_s {
  color: #000;
  font-weight: bold;
}
.nav_wrap .btn_wrap {
  float: right;
  height: 70px;
  line-height: 70px;
  display: flex;
  align-items: center;
}
.nav_wrap .btn_wrap .btn_wrap_ctn {
  line-height: 70px;
  font-size: 18px;
  margin-right: 5px;
}
.nav_wrap .btn_wrap .btn_wrap_ctn .btn_item {
  display: inline-flex;
  margin: 0 10px;
  font-size: 16px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  color: #333333;
  line-height: 18px;
  user-select: none;
  cursor: pointer;
}
.nav_wrap .btn_wrap .btn_wrap_ctn .btn_item a {
  font-size: 16px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  color: #333333;
  line-height: 18px;
  user-select: none;
  cursor: pointer;
}

.nav_wrap .btn_wrap .btn_wrap_ctn .invite_item {
  color: #ff781e;
  display: initial;
}
.nav_wrap .btn_wrap .btn_wrap_ctn .invite_item img {
  margin-right: 5px;
  margin-bottom: 5px;
}
.nav_wrap_child {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  background: #fff;
  z-index: 1000;
  font-size: 16px;
  color: #333333;
  height: 70px;
  width: 1200px;
  margin: 0 auto;
}
.nav_wrap_child .btn_wrap {
  margin-left: 100px;
}
.nav_wrap_child .btn_wrap a {
  width: 136px;
  height: 42px;
  line-height: 42px !important;
  text-align: center;
  display: block;
  color: #5280ff !important;
  background-color: #e5ecff;
  border-radius: 6px;
  text-decoration: none;
}
</style>
